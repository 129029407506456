/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable react/no-unused-prop-types */
// @flow

import React from 'react';
import moment from 'moment';
import {
  displayPrice,
  extractContractIptTax,
} from '../../../network/api/eqsApi';
import type { DCXType } from '../../../types/DCXTypes';
import type { ContractListRS } from '../../../types/QuotationTypes';
import type { QuoteFormType } from '../../../types/QuoteFormTypes';
import TermsAndConditions from './TermsAndConditions';

type Props = {
  DCX: DCXType,
  quoteForm: QuoteFormType,
  quoteFormFront: Object,
  offer: ContractListRS,
  callToAction: string,
  detailSummaryTitleColor: string,
  detailSummaryPanelColor: string,
  countries: Object[],
  quotationIptTaxesLabel: ?string,
  showTaxes: boolean,
};

function orElse(valueIfPresent: any, or: any) {
  return valueIfPresent || or;
}

export default function Summary(props: Props) {
  const { agebandLabels } = props.quoteForm.quoteLabelTranslation.quoteLabelTranslation;
  const quoteFormData = JSON.parse(localStorage.getItem('quoteFormFront'));
  const travellerTranslation = (ageband) => {
    if (JSON.parse(agebandLabels)[ageband] !== '') {
      return JSON.parse(agebandLabels)[ageband];
    }
    return ageband;
  };
  if (props.DCX === null || !props.quoteForm) {
    return null; // Return null instead of false for better React practice
  }
  const iptTax = extractContractIptTax(props.offer);
  const countryName = countryCode => orElse(
    props
      .countries
      .find(country => country.countryCode === countryCode),
    { countryName: props.quoteForm.mainQuoteData.destination === 'geozone' || props.quoteForm.additionnalQuoteData.destination ? props.geozones[countryCode] : '' },
  ).countryName;

  const IsPromocodeEnable = props.quoteForm.additionnalQuoteData.promoCode;
  const promotionCode = (props.offer.recommendedPremium.discounts.length > 0) ? props.offer.recommendedPremium.discounts[0].discountCode : "[Empty]";
  const grossPremium = props.offer.recommendedPremium.grossPremium.amount;
  const originalGrossPremium = props.offer.recommendedPremium.originalGrossPremium.amount;
  const travellers = props.quoteForm.mainQuoteData.travellers;
  const isTravellersNumber = travellers == 'number' ? true : false;

  return (
    <section className="summary-container" aria-labelledby="summary-title">
      <header
        className="summary-title"
        id="summary-title"
        style={{ color: props.detailSummaryTitleColor || '#000' }}
      >
        {props.quoteForm.quoteSummaryTranslation.summary}
      </header>
      <hr aria-hidden="true" />
      <div className="summary-body">
        {quoteFormData && quoteFormData.tripType === 'single' && (
          <p>
            {`${props.quoteForm.quoteSummaryTranslation.startDate} `}
            <strong>{moment(props.quoteFormFront.dateRange.startDate).format('L')}</strong>
            {` ${props.quoteForm.quoteSummaryTranslation.endDate} `}
            <strong>{moment(props.quoteFormFront.dateRange.endDate).format('L')}</strong>
          </p>
        )}
        {quoteFormData && quoteFormData.tripType === 'annual' && (
          <p>
            {`${props.quoteForm.quoteSummaryTranslation.annualStartDate} `}
            <strong>{moment(props.quoteFormFront.dateRange.startDate).format('L')}</strong>
            {` ${props.quoteForm.quoteSummaryTranslation.annualEndDate} `}
            <strong>{moment(props.quoteFormFront.dateRange.endDate).format('L')}</strong>
          </p>
        )}
        <p>
          {`${props.quoteForm.quoteSummaryTranslation.productName} `}
          <strong>{props.offer.productOfferingName}</strong>
        </p>
        {IsPromocodeEnable && promotionCode !== "[Empty]" && (
          <>
            <p>
              {`${props.quoteForm.quoteSummaryTranslation.promoCode} `}
              <strong>{promotionCode}</strong>
            </p>
          </>
        )}
        <p>
          {`${props.quoteForm.quoteSummaryTranslation.travelFromCountry} `}
          <strong>{countryName(props.quoteFormFront.departureCountry)}</strong>
          {` ${props.quoteForm.quoteSummaryTranslation.travelTo} `}
          <strong>{countryName(props.quoteFormFront.destination)}</strong>
        </p>
        <p>
          {`${props.quoteForm.quoteSummaryTranslation.currencyDetails} `}
          <strong>{props.DCX.currency || ''}</strong>
        </p>
        {Boolean(travellers) && (
          <div> 
            {isTravellersNumber && props.quoteFormFront.travellers}   
            { props.quoteFormFront.numberOfTravellers > 1
              ? ` ${props.quoteForm.quoteSummaryTranslation.travellers}`
              : ` ${props.quoteForm.quoteSummaryTranslation.traveller}`
            }
            
            {!isTravellersNumber &&
              Object.keys(props.quoteFormFront.travellers || {}).map(ageband => (
                <div key={ageband}>
                  {props.quoteForm.quoteLabelTranslation.quoteLabelTranslation.agebandLabels && (
                    <strong>{`${props.quoteFormFront.travellers[ageband]} ${travellerTranslation(ageband)}`}</strong>
                  )}
                </div>
              ))
            }
          </div>
        )}
        <TermsAndConditions offer={props.offer} quoteSummaryTranslation={props.quoteForm.quoteSummaryTranslation} />
      </div>
      <footer
        className="summary-footer"
        style={{ background: props.detailSummaryPanelColor }}
      >
        {(!IsPromocodeEnable || grossPremium === originalGrossPremium) && (
          <div className="price">
            {`${displayPrice(props.offer.recommendedPremium.grossPremium.amount)} ${props.DCX.currency || ''}`}
          </div>
        )}
        {IsPromocodeEnable && grossPremium < originalGrossPremium && (
          <div className="price">
            {`${displayPrice(grossPremium)}/`}
            <s>{`${displayPrice(originalGrossPremium)}`}</s>
            {` ${props.DCX.currency || ''}`}
          </div>
        )}
        {props.showTaxes && iptTax && (
          <div className="ipt-tax">
            {props.quotationIptTaxesLabel} {displayPrice(iptTax.totalTaxAmount.amount)} {iptTax.totalTaxAmount.currency}
          </div>
        )}
        <div>{props.quoteForm.quoteSummaryTranslation.totalPrice}</div>
      </footer>
    </section>
  );
}
